<template>
  <CardToolbox>
    <SearchHeaderStyle>
      <sdPageHeader title="Partners">
        <template #buttons>
          <sdButton @click="showModal" class="btn-add_new" size="default" type="primary" key="1">
            <a to="#">+ Add New</a>
          </sdButton>
        </template>
      </sdPageHeader>
    </SearchHeaderStyle>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Filters ">
          <a-form :model="filterForm" layout="vertical">
            <a-row :gutter="25">
              <a-col :md="8" :xs="8">
                <a-form-item label="Partner Name" name="partnerName">
                  <a-input v-model:value="filterForm.partnerName" placeholder="Search by Name" style="height:38px" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :xs="8">
                <a-form-item label="Activity" name="activitiesID">
                  <a-select v-model:value="filterForm.activityId" :options="activityList"
                    :filter-option="selectFilterOption" placeholder="Activities">
                    <a-select-option v-for="(activity, index) in activityList" :key="index" :value="activity.id">
                      {{ activity.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="Country" name="countryId">
                  <a-select v-model:value="filterForm.countryId" show-search placeholder="Select a person"
                    style="width: 100%" :options="countryList" :filter-option="selectFilterOption"></a-select>
                </a-form-item>
              </a-col>

              <a-col :span="6" class="button-container">
                <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary" @click="onFilter()">
                  <span>Filter</span>
                </a-button>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button class="ant-btn-lg" @click="clearFilters()" danger>
                  <span>Clear Filters</span>
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
      </a-col>
    </a-row>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards headless>
          <div>
            <TableWrapper class="table-data-view table-responsive">
              <a-table :pagination="pagination" :dataSource="dataSource" :columns="columns" :loading="isLoading"
                @change="onHandleTableChange" />
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
      <sdModal :type="localState.modalType" title="Partner's information" :visible="localState.visible" :footer="null"
        :onCancel="handleCancel">
        <div class="project-modal">
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState" :rules="rules"
                :layout="formState.layout">
                <a-row :gutter="25">
                  <a-col :md="12" :xs="24">
                    <a-form-item ref="name" label="Full name" name="name">
                      <a-input v-model:value="formState.name" placeholder="Full name" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item label="HS code" name="hsCodeId">
                      <a-select :size="'small'" mode="multiple" v-model:value="formState.hsCodesIDs" placeholder="HS Code"
                        show-search style="width: 100%" :options="hsCodeData"
                        :not-found-content="hsCodeFetching ? undefined : null" :filter-option="false"
                        @search="searchHSCode">
                        <template v-if="hsCodeFetching" #notFoundContent>
                          <a-spin size="small" />
                        </template>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item label="Activity" name="activitiesID">
                      <a-select :size="'small'" mode="multiple" v-model:value="formState.activitiesID"
                        :options="activityList" :filter-option="selectFilterOption" placeholder="Activities">
                        <a-select-option v-for="(activity, index) in activityList" :key="index" :value="activity.id">
                          {{ activity.name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item label="Class">
                      <a-checkbox v-model:checked="formState.isOperation" name="operations">Operations</a-checkbox>
                      <a-checkbox v-model:checked="formState.isSupport" name="support">Support</a-checkbox>
                    </a-form-item> </a-col>
                  <a-col v-if="hasProductLine" :md="12" :xs="24">
                    <a-form-item label="Line Product" name="productLineIDs">
                      <a-select :size="'small'" mode="multiple" :options="productLineList"
                        :filter-option="selectFilterOption" v-model:value="formState.productLineIDs"
                        placeholder="Line Product">
                        <a-select-option v-for="(productLine, index) in productLineList" :key="index"
                          :value="productLine.id">
                          {{ productLine.name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item name="email" label="Email">
                      <a-input v-model:value="formState.email" :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item name="phoneNumber" label="Contact number ">
                      <a-input v-model:value="formState.phoneNumber" :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item name="taxRegistration" label="Tax Registration">
                      <a-input v-model:value="formState.taxRegistration" :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item name="numeroRegistreCommerce" label="Commerce Registration">
                      <a-input v-model:value="formState.numeroRegistreCommerce" :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item name="city" label="City">
                      <a-input v-model:value="formState.city" :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="12" :xs="24">
                    <a-form-item name="postalCode" label="Postal Code">
                      <a-input v-model:value="formState.postalCode" :style="{ width: '100%' }" />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="24">
                    <a-form-item label="Adress">
                      <a-textarea v-model:value="formState.address" :style="{ width: '100%' }" placeholder="Adresse ..."
                        name="address" class="sDash_unresizable" />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="24">
                    <a-form-item label="Country" name="countryId">
                      <a-select v-model:value="formState.countryId" show-search placeholder="Select a person"
                        style="width: 100%" :options="countryList" :filter-option="selectFilterOption"></a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="24">
                    <sdButton htmlType="submit" size="full" type="primary" key="submit">
                      <span v-if="!formState.editionMode">Add partner</span>
                      <span v-if="formState.editionMode">Edit</span>
                    </sdButton>
                  </a-col>
                </a-row>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </div>
      </sdModal>
    </a-row>
  </Main>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { RecordViewWrapper } from "./style";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, watch, toRefs } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { CardStyleWrapper } from "../../view/uiElements/ui-elements-styled";
import { DataService } from "@/config/dataService/dataService";
import { debounce } from "lodash-es";
const columns = [
  {
    title: "Partnair name",
    dataIndex: "partner.name",
    key: "name",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Email",
    dataIndex: "partner.email",
    key: "partner.email",
  },
  {
    title: "Contact number",
    dataIndex: "partner.phoneNumber",
    key: "partner.phoneNumber",
  },
  {
    title: "Tax Registration",
    dataIndex: "partner.taxRegistration",
    key: "partner.taxRegistration",
  },
  {
    title: "Commerce Registration",
    dataIndex: "partner.numeroRegistreCommerce",
    key: "partner.numeroRegistreCommerce",
  },
  // {
  //   title: "Address",
  //   dataIndex: "partner.address",
  //   key: "partner.address",
  // },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];
const bankDetailsColumns = [
  {
    title: "Currency",
    dataIndex: "bankDetail.currencyName",
    key: "bankDetail.currencyName",
  },
  {
    title: "Bank",
    dataIndex: "bankDetail.banque",
    key: "bankDetail.banque",
  },
  {
    title: "Account number",
    dataIndex: "bankDetail.numeroCompte",
    key: "bankDetail.numeroCompte",
  },
  {
    title: "RIB",
    dataIndex: "bankDetail.rib",
    key: "bankDetail.rib",
  },
  {
    title: "IBAN",
    dataIndex: "bankDetail.iban",
    key: "bankDetail.iban",
  },
  {
    title: "Swift",
    dataIndex: "bankDetail.codeSwift",
    key: "bankDetail.codeSwift",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];
const bankActions = (partner) => {
  return (
    <div>
      <a to="#">
        <sdFeatherIcons type="printer" size="16" />
        <span>{partner.name}</span>
      </a>
      <a to="#">
        <sdFeatherIcons type="book-open" size="16" />
        <span>PDF</span>
      </a>
      <a to="#">
        <sdFeatherIcons type="file-text" size="16" />
        <span>Google Sheets</span>
      </a>
      <a to="#">
        <sdFeatherIcons type="x" size="16" />
        <span>Excel (XLSX)</span>
      </a>
      <a to="#">
        <sdFeatherIcons type="file" size="16" />
        <span>CSV</span>
      </a>
    </div>
  );
};
const Partners = {
  name: "Partners",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    RecordViewWrapper,
    CardToolbox,
    SearchHeaderStyle,
    CardStyleWrapper,
    bankActions,
  },
  setup() {
    const { state, dispatch } = useStore();
    const searchValue = ref("");
    const showBankModal = ref(false);
    const partnerApi = computed(() => state.partnerApi.data.data);
    const ActivityApi = computed(() => state.ActivityApi.data);
    const ProductLinesApi = computed(() => state.ProductLineApi.data);
    const bankData = computed(() => state.bankApi.data);
    const CurrencyApi = computed(() => state.CurrencyApi.data);
    var partnerPagination = computed(() => state.partnerApi.data);
    const CountryApi = computed(() => state.CountryApi.data);

    const isLoading = computed(() => state.partnerApi.loading);
    var loading = false;
    var sortDirections = "asc";
    var formState = reactive({
      layout: "vertical",
      id: 0,
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      countryId: "",
      isOperation: false,
      isSupport: false,
      city: "",
      postalCode: "",
      activitiesID: [],
      productLineIDs: [],
      editionMode: false,
    });
    var bankState = reactive({
      layout: "vertical",
      partenaireId: 0,
      banque: "",
      numeroCompte: "",
      rib: "",
      iban: "",
      codeSwift: [],
      currencyId: null,
      editionMode: false,
    });
    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });
    const formRef = ref();

    const rules = {
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
        {
          type: "email",
          message: "The e-mail is not valid",

          trigger: "change",
        },
      ],
      activitiesID: [
        {
          type: "array",
          required: true,
          message: "Select activities",
          trigger: "change",
        },
      ],
      productLineIDs: [
        {
          type: "array",
          required: true,
          message: "Select product line",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("partnerGetAllData", pagination.value);
      dispatch("currencyInfo");
      dispatch("activityInfo");
      dispatch("productLineInfo");
      dispatch("countryInfo");
    });

    onBeforeRouteLeave(() => {
      dispatch("emptyPartnerData");
      pagination = {
        current: 1,
        pageSize: 10000,
      };
    });

    const showModal = () => {
      formState.editionMode = false;
      delete formState.id;
      formState.name = "";
      formState.email = "";
      formState.phoneNumber = "";
      formState.address = "";
      formState.taxRegistration = "";
      formState.numeroRegistreCommerce = "";
      formState.activitiesID = [];
      formState.HSCodesIDs = [];
      formState.productLineIDs = [];
      formState.countryId = "";
      formState.city = "";
      formState.isOperation = false;
      formState.isSupport = false;
      formState.postalCode = "";
      localState.visible = true;
    };

    const handleDelete = (partnerData) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("partnerApiDataDelete", {
          id: partnerData.id,
        });
      }
      return false;
    };
    const selectFilterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const countryList = computed(() => {
      CountryApi.value && CountryApi.value.length;
      if (CountryApi.value && CountryApi.value.length) {
        return CountryApi.value.map((country, key) => {
          return {
            key: key + 1,
            label: country.name,
            value: country.id,
          };
        });
      } else {
        return [];
      }
    });
    const productLineList = computed(() => {
      ProductLinesApi.value && ProductLinesApi.value.length;
      if (ProductLinesApi.value && ProductLinesApi.value.length) {
        return ProductLinesApi.value.map((productLine, key) => {
          return {
            key: key + 1,
            label: productLine.name,
            value: productLine.id,
          };
        });
      } else {
        return [];
      }
    });
    const activityList = computed(() => {
      ActivityApi.value && ActivityApi.value.length;
      if (ActivityApi.value && ActivityApi.value.length) {
        return ActivityApi.value.map((activity, key) => {
          return {
            key: key + 1,
            label: activity.name,
            value: activity.id,
          };
        });
      } else {
        return [];
      }
    });

    const handleEdit = (partner) => {
      getPartnerHsCode(partner.id);
      formState.editionMode = true;
      formState.id = partner.id;
      formState.name = partner.name;
      formState.email = partner.email;
      formState.phoneNumber = partner.phoneNumber;
      formState.address = partner.address;
      formState.taxRegistration = partner.taxRegistration;
      formState.countryId = partner.countryId;
      formState.city = partner.city;
      formState.postalCode = partner.postalCode;
      formState.isOperation = partner.isOperation;
      formState.isSupport = partner.isSupport;
      formState.numeroRegistreCommerce = partner.numeroRegistreCommerce;
      formState.activitiesID = [];
      if (partner.activitiesID && partner.activitiesID.length) {
        formState.activitiesID = partner.activitiesID;
      } else if (partner.activities) {
        formState.activitiesID = partner.activities.map((a) => a.id);
      }

      if (partner.hsCodesIDs && partner.hsCodesIDs.length) {
        formState.hsCodesIDs = partner.hsCodesIDs;
      } else if (partner.hsCodes) {
        formState.hsCodesIDs = partner.hsCodes.map((a) => a.id);
      }
      formState.productLineIDs = [];
      if (partner.productLineIDs && partner.productLineIDs.length) {
        formState.productLineIDs = partner.productLineIDs;
      } else if (partner.productLines) {
        formState.productLineIDs = partner.productLines.map((a) => a.id);
      }
      localState.visible = true;
    };

    const onHandleSearch = () => {
      pagination = {
        ...pagination,
        current: 1,
        sorter: {
          order: "asc",
        },
        orderBy: "name",
        pageSize: 10,
        searchValue: searchValue.value,
      };

      dispatch("partnerGetAllData", pagination);
    };
    const onHandleTableChange = (pagination, filtr, sorter) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sorter.order == "descend" ? "desc" : "asc",
        },
        current: pagination.current,
        orderBy: sorter.columnKey ?? "",
        searchValue: searchValue.value,
      };

      dispatch("partnerGetAllData", pagination);
    };

    const dataSource = computed(() =>
      partnerApi.value && partnerApi.value.length
        ? partnerApi.value.map((partner, key) => {
          return {
            key: key + 1,
            partner,
            action: (
              <div class="table-actions">
                <router-link class="edit" to={"/partner-info/" + partner.id}>
                  <sdFeatherIcons type="eye" size={14} />
                </router-link>
                &nbsp;&nbsp;&nbsp;
                <a-button class="edit" onClick={() => handleEdit(partner)}>
                  <sdFeatherIcons type="edit" size={14} />
                </a-button>
                &nbsp;&nbsp;&nbsp;
                <a-button
                  class="delete"
                  onClick={() => handleDelete(partner)}
                >
                  <sdFeatherIcons type="trash-2" size={14} />
                </a-button>
              </div>
            ),
          };
        })
        : []
    );
    var pagination = computed(() =>
      partnerPagination.value && partnerPagination.value.succeeded == true
        ? {
          current: partnerPagination.value.pageNumber,
          filters: filterForm,
          total: searchValue.value
            ? partnerPagination.value.recordsFiltered
            : partnerPagination.value.recordsTotal,
          pageSize: partnerPagination.value.pageSize,
          orderBy: "name",
          searchValue: "",
          sorter: {
            order: sortDirections,
          },
        }
        : {
          current: 1,
          pageSize: 10,
          filters: filterForm,
          orderBy: "name",
          searchValue: "",
          sorter: {
            order: sortDirections,
          },
        }
    );

    const hasProductLine = computed(() => {
      let count = 0;
      if (formState.activitiesID.length) {
        formState.activitiesID.forEach((activityId) => {
          const selectedActivities = ActivityApi.value.filter((activity) =>
            formState.activitiesID.includes(activity.id)
          );
          const filteredData = selectedActivities.filter(
            (activity) => activity.id === activityId && activity.hasProductLine
          );
          if (filteredData.length > 0) {
            count++;
          }
        });
      }
      return count;
    });

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (!formState.editionMode) {
            dispatch("partnerSubmitData", {
              formState,
            });
          } else {
            dispatch("partnerUpdateData", {
              formState,
            });
          }
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onCancel = () => {
      localState.visible = false;
      localState.editVisible = false;
      formState.editionMode = false;
    };
    ////////// bank detail

    const showBankInfoModal = (partner) => {
      bankState.editionMode = false;
      bankState.partenaireId = partner.id;
      bankState.partenaireName = partner.name;
      bankState.banque = "";
      bankState.numeroCompte = "";
      bankState.rib = "";
      bankState.iban = "";
      bankState.codeSwift = "";
      bankState.currencyId = null;
      dispatch("bankDetailsByPartner", partner);
    };

    const handleOk = () => {
      dispatch("emptyBankDetails");
      showBankModal.value = false;
    };
    const onSubmitBankInfo = () => {
      formRef.value
        .validate()
        .then(() => {
          if (!bankState.editionMode) {
            delete bankState.id;
            dispatch("bankDetailsSubmitData", {
              bankState,
            });
          } else {
            dispatch("bankDetailsUpdateData", {
              bankState,
            });
          }
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const handleBankInfoDelete = (bank) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("bankDetailsApiDataDelete", {
          id: bank.id,
        });
      }
      return false;
    };
    const handleBankInfoEdit = (bank) => {
      bankState.editionMode = true;
      bankState.id = bank.id;
      bankState.partenaireId = bank.partenaireId;
      bankState.partenaireName = bank.partenaireName;
      bankState.banque = bank.banque;
      bankState.numeroCompte = bank.numeroCompte;
      bankState.rib = bank.rib;
      bankState.iban = bank.iban;
      bankState.codeSwift = bank.codeSwift;
      bankState.currencyId = bank.currencyId;
    };
    const handleBankInfoCancelEdit = () => {
      bankState.editionMode = false;
      bankState.partenaireId = null;
      bankState.banque = "";
      bankState.numeroCompte = "";
      bankState.rib = "";
      bankState.iban = "";
      bankState.codeSwift = "";
      bankState.currencyId = null;
    };
    ///// HS CODE
    const hsCodeSearchState = reactive({
      hsCodeData: [],
      hsCodeValue: [],
      hsCodeFetching: false,
    });
    const getPartnerHsCode = async (hsCodeId) => {
      const query = await DataService.get(
        `api/HSCode/GetByPartenaireId?PartenaireId=${hsCodeId}`
      );
      if (query.succeeded) {
        const data = query.data.map((element) => ({
          label: element.hsCodeName,
          value: element.hsCodeId,
        }));
        hsCodeSearchState.hsCodeData = data;
      }
    };
    let lasthsCodeFetchId = 0;

    const searchHSCode = debounce(async (hscode) => {
      lasthsCodeFetchId += 1;
      const fetchId = lasthsCodeFetchId;
      hsCodeSearchState.hsCodeData = [];
      hsCodeSearchState.hsCodeFetching = true;
      DataService.get(
        `api/HSCode?orderBy=name&HSCodeName=${hscode}&PageSize=10`
      )
        .then((response) => response.data)
        .then((list) => {
          if (fetchId !== lasthsCodeFetchId) {
            return;
          }
          const data = list.map((element) => ({
            label: element.name,
            value: element.id,
          }));
          hsCodeSearchState.hsCodeData = data;
          hsCodeSearchState.hsCodeFetching = false;
        });
    }, 300);
    watch(formState.hsCodesIDs, () => {
      hsCodeSearchState.data = [];
      hsCodeSearchState.hsCodeFetching = false;
    });


    var filterForm = reactive({
      partnerName: "",
      activityId: "",
      countryId: "",
    });

    const onFilter = () => {

      pagination.value = {
        ...pagination,
        current: 1,
        pageSize: 10,
        filters: filterForm.value,
      }
      dispatch("partnerGetAllData", pagination.value);
    }
    const clearFilters = () => {
      filterForm.partnerName = "";
      filterForm.activityId = "";
      filterForm.countryId = "";
      onFilter();
    }

    return {
      ...toRefs(hsCodeSearchState),
      partnerApi,
      bankDetailsColumns,
      formRef,
      rules,
      onSubmit,
      onSubmitBankInfo,
      isLoading,
      handleDelete,
      handleEdit,
      dataSource,
      columns,
      formState,
      localState,
      showModal,
      handleCancel,
      onHandleSearch,
      onHandleTableChange,
      pagination,
      loading,
      showBankModal,
      showBankInfoModal,
      handleOk,
      handleBankInfoDelete,
      handleBankInfoEdit,
      handleBankInfoCancelEdit,
      bankData,
      bankState,
      ActivityApi,
      ProductLinesApi,
      CurrencyApi,
      searchValue,
      countryList,
      searchHSCode,
      selectFilterOption,
      hasProductLine,
      productLineList,
      activityList,
      filterForm,
      onFilter,
      clearFilters,
    };
  },
};

export default Partners;
</script>
<style>
.ant-btn-lg {
  height: 38px;
  width: 100%;
}
</style>